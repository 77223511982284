import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import { theme } from "../styles/theme"

const ImageLinkItem = ({
  destination,
  destinationState,
  title,
  subtitle,
  imageName,
  small,
  colour,
}) => {
  return (
    <Link
      to={destination}
      state={destinationState}
      css={[
        theme.components.button,
        theme.components.buttonGrey,
        styles.link,
        css({
          color: colour ? theme.colours[colour] : theme.colours.red,
        }),
      ]}
    >
      <img
        src={imageName}
        alt={title}
        css={small ? styles.smallImage : styles.image}
        width={small ? 88 : 141}
        height={small ? 88 : 141}
      />
      <div css={styles.titleContainer}>
        <p css={styles.title}>{title}</p>
        {subtitle && <p css={styles.subtitle}>{subtitle}</p>}
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.421"
        height="25.88"
        viewBox="0 0 15.421 25.88"
        css={{
          flexShrink: 0,
        }}
      >
        <path
          d="M75.381,47.266c-.041-.041-.087-.069-.13-.107L64.9,36.809a2.471,2.471,0,0,0-3.494,0h0a2.471,2.471,0,0,0,0,3.494l8.722,8.722-8.722,8.723a2.471,2.471,0,0,0,0,3.494h0a2.471,2.471,0,0,0,3.494,0L75.251,50.89c.043-.037.09-.066.13-.107a2.5,2.5,0,0,0,0-3.517Z"
          transform="translate(-60.682 -36.085)"
          fill="currentColor"
        />
      </svg>
    </Link>
  )
}

const styles = {
  link: css({
    display: "flex",
    alignItems: "center",
    padding: theme.gapHalf,
    paddingRight: theme.gap,
  }),
  titleContainer: css({
    marginRight: theme.gapDouble,
    textAlign: "left",
    flexGrow: 1,
  }),
  title: css({
    fontSize: theme.fzH3,
  }),
  subtitle: css({
    fontSize: "1rem",
    marginTop: theme.gapHalf,
    maxWidth: "30ch",
  }),
  image: css({
    width: "33%",
    flexShrink: 0,
    maxWidth: 141,
    height: "auto",
    marginRight: theme.gap,
  }),
  smallImage: css({
    width: 88,
    height: "auto",
    marginRight: theme.gap,
  }),
}

export default ImageLinkItem
