import React from "react"
import { Redirect } from "@reach/router"
import { css } from "@emotion/core"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CustomNavTitle from "../../components/CustomNavTitle"
import ImageLinkItem from "../../components/ImageLinkItem"

import listImage_rc from "../../images/listimages/listimage-red-cells.svg"
import listImage_platelets from "../../images/listimages/listimage-platelets.svg"
import listImage_plasma from "../../images/listimages/listimage-plasma.svg"

import { theme } from "../../styles/theme"

const ComponentChoice = ({ location }) => {
  if (!location.state) {
    return <Redirect to="/compatibility/" noThrow />
  }

  const { state = { group: null } } = location
  const { group } = state

  if (!group) {
    return <Redirect to="/compatibility/" noThrow />
  }

  return (
    <Layout>
      <SEO title="Blood Components Compatibility" />

      <CustomNavTitle
        title="Blood Components Compatibility"
        destination="/compatibility/"
        location={location}
      />

      <article className="mt-gap-2" css={theme.components.row}>
        <header css={styles.header}>
          <p css={theme.components.bloodGroupIcon}>{group}</p>
          <h2 css={theme.components.title}>
            Now please select a blood component
          </h2>
        </header>

        <ul css={styles.list}>
          <li>
            <ImageLinkItem
              destination="/compatibility/results"
              destinationState={{
                group,
                component: "redCells",
              }}
              imageName={listImage_rc}
              title="Red Cells"
              small
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/compatibility/results"
              destinationState={{
                group,
                component: "platelets",
              }}
              imageName={listImage_platelets}
              title="Platelets"
              small
              colour="blue"
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/compatibility/results"
              destinationState={{
                group,
                component: "plasma",
              }}
              imageName={listImage_plasma}
              title="FFP / Cryo"
              subtitle="Includes coronavirus convalescent plasma (CCP) but not Octaplas LG"
              small
              colour="yellow"
            />
          </li>
        </ul>
      </article>
    </Layout>
  )
}

const styles = {
  header: css({
    color: theme.colours.black,
    marginBottom: theme.gapDouble,
    borderBottom: `2px solid ${theme.colours.lightGrey}`,
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.gap,
  }),
  list: css({
    listStyle: "none",
    marginBottom: theme.gap,
    "&> *": {
      display: "inline-block",
      marginRight: theme.gap,
      marginBottom: theme.gap,
      minWidth: 350,
      "@media (max-width: 620px)": {
        width: "100%",
        minWidth: 0,
        marginRight: 0,
      },
    },
  }),
}

export default ComponentChoice
